import { Spinner } from 'react-bootstrap'

function Loader() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#ffffffab',
        zIndex: 9998,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '45%',
          left: '50%',
          textAlign: 'center',
          zIndex: 9999,
        }}
      >
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    </div>
  )
}

export default Loader
