import { LogoBig } from './Icons'

function FormsLogo() {
  return (
    <div className='d-flex justify-content-center margin_bottom-24'>
      <LogoBig />
    </div>
  )
}

export default FormsLogo
