import { FormEvent, useEffect, useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import FormsLogo from 'src/components/FormsLogo'
import Loader from 'src/components/Loader'
import Link from 'next/link'
import { EMAIL_VALIDATOR } from 'src/config/validators'
import { getToppLink, loginWithEmailPassword } from 'src/requests/auth'
import { setLiteTokens, setAuthTokens } from 'src/utils/nookies'
import { useFormBackground } from 'src/helpers/hooks/auth'
import { withOutAuth } from 'src/helpers/hoc'
import Metadata from 'src/components/Metadata'
import { useUserData } from 'src/helpers/hooks/user'
import { useRouter } from 'next/router'
import toast from 'react-hot-toast'

function Login() {
  useFormBackground()
  const [working, setWorking] = useState(false)
  const [email, setEmail] = useState('')
  const [isEmailInvalid, setIsEmailInvalid] = useState(false)
  const [password, setPassword] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)
  const auth = useUserData()
  const router = useRouter()
  useEffect(() => {
    auth.logout()
    console.log('auth logout')
  }, [])
  useEffect(() => {
    // route topp
    const is_topp = auth.user?.organizations[0]?.is_topp

    if (is_topp) {
      getToppLink()
      router.replace('tpp')
    } else if (!auth.validating && !auth.error && !!auth.user && loggedIn && !auth?.is_topp) {
      router.replace('dashboard')
    }
  }, [auth.validating, auth.error, auth.user, loggedIn])
  console.log(isEmailInvalid)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setWorking(true)
      const { data } = await loginWithEmailPassword(email, password)

      if (data?.is_topp) {
        setLiteTokens(data?.refresh)
      } else {
        setAuthTokens(data?.refresh, data?.access)
      }

      auth.mutate()
      setLoggedIn(true)
    } catch (error: any) {
      if (error?.response?.data?.message == 'No active account found with the given credentials') {
        toast.error('This email and password combination is incorrect.', { style: { backgroundColor: '#fff0ec', borderColor: '#fa6440', fontWeight: 300, color: '#fa6440', textAlign: 'center' } })
      }
    } finally {
      setWorking(false)
    }
  }
  const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  return (
    <div className='loginForm'>
      <Metadata title='Quick Organic Login' />

      {working && <Loader />}

      <Container>
        <Row>
          <Col>
            <Form className='form_login color_bg-white color_border-gray--08' onSubmit={(e) => handleSubmit(e)}>
              <FormsLogo />
              <div>
                <FloatingLabel controlId='loginFloatingEmail' label='Email Address' className={'mb-3 ' + (email ? 'para-7' : 'para-5')}>
                  <Form.Control
                    type='email'
                    placeholder='Email Address'
                    name='email'
                    className={`form_input para-5 login_email-invalid`}
                    value={email}
                    onChange={({ target }) => {
                      const newEmail = target.value
                      setEmail(newEmail)

                      if (newEmail.trim() === '') {
                        setIsEmailInvalid(false)
                      } else {
                        emailPattern.test(newEmail) ? setIsEmailInvalid(false) : setIsEmailInvalid(true)
                      }
                    }}
                  />
                </FloatingLabel>

                {isEmailInvalid && <p className='para-6 color_text-secondary fw-400'>Please enter a valid email address</p>}
                <FloatingLabel controlId='loginFloatingPassword' label='Password' className={'mb-3 ' + (password ? 'para-7' : 'para-5')}>
                  <Form.Control
                    type='password'
                    placeholder='Password'
                    name='password'
                    className='form_input
                  para-5'
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                </FloatingLabel>

                <Button variant='primary w-100' type='submit' className='form_btn para-5 fw-300' disabled={!EMAIL_VALIDATOR.test(email) || !password}>
                  Log In
                </Button>
                <p className='text-center' style={{ marginTop: '1.5em' }}>
                  <Link href='/forget-password'>
                    <a
                      className='para-5 fw-300 color_text-primary
                    link_hover link_underline-none'
                    >
                      Forgot Password?
                    </a>
                  </Link>
                </p>

                <p className='fw-400 color_text-gray--01 text-center para-5' style={{ marginTop: '1.5em' }}>
                  <span className='fw-300 color_text-gray--01 para-5'>No account?</span>
                  &nbsp;
                  <Link href='/signup'>
                    <a
                      className='para-5 fw-300 color_text-primary
                    link_hover link_underline-none'
                    >
                      Create one
                    </a>
                  </Link>
                </p>
                <p className='text-center' style={{ marginTop: '1.5em' }}>
                  <Link href='/help'>
                    <a
                      className='para-5 fw-300 color_text-primary
                    link_hover link_underline-none'
                    >
                      Need help?
                    </a>
                  </Link>
                </p>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withOutAuth(Login)
